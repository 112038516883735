import React from 'react';
import Context from '../../assets/js/Context';
import Ca1 from '../../assets/images/ca1.jpg';
import Ca2 from '../../assets/images/ca2.jpg';
import PrintIcon from '@material-ui/icons/Print';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Spinner } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import host from '../../assets/js/Host';
import * as moment from "moment";
import axios from 'axios';
import Cookies from "universal-cookie";

const cookies = new Cookies();
function print() {
    var printContents = document.getElementById('ifmcontentstoprintshop').innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
}

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            spinPage: true,
        }
    }
    componentDidMount() {
        var header = { "Content-Type": "application/json", Authorization: cookies.get("tokenAdmin") };
        axios.get(host + `item/from/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                console.log(res.data.item);
                this.setState({
                    items: res.data.item,
                    spinPage: false
                });
            })
            .catch(error => {

                console.log(error.response)
            })
    }

    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    return (
                        !this.state.spinPage ?
                            <div>
                                <div id='AppBarPageContiner'>
                                    <div id='AppbarContiner' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                        <Link to={`/InfoDash/${this.props.match.params.id}`} >
                                            <ArrowBack style={{ color: '#fff', cursor: 'pointer' }} />
                                        </Link>
                                        <div style={{ width: 100, display: 'flex', alignItems: 'center', justifyContent: 'space-around' }} >

                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                    <div style={{ width: 200, height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                                        <div id='MenuBtnCateCont'
                                            style={{
                                                width: 120, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', cursor: 'pointer',
                                                backgroundColor: '#4887ff', borderRadius: 5, boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px'
                                            }}
                                            onClick={() => {


                                                setTimeout(() => {
                                                    print()
                                                }, 200);
                                            }}><span>طباعه</span> <PrintIcon /> </div>
                                    </div>


                                    <div id='ifmcontentstoprintshop'>
                                        <div style={{ width: '5.06in', height: '3.175in', position: 'relative' }}>
                                            <div style={{ position: 'absolute', zIndex: 1 }}>
                                                <img src={Ca1} alt='card' style={{ width: '5.06in', height: '3.175in' }} />
                                            </div>
                                            <div style={{ width: '5.06in', height: '3.175in', position: 'absolute', zIndex: 2, display: 'flex' }}>
                                                <div style={{ marginTop: 100, marginLeft: 31 }}>
                                                    <img src={host + 'item/files/' + this.state.items.photo} alt='card' style={{ width: 100, height: 'auto', borderRadius: 10 }} />
                                                </div>
                                                <div style={{ position: 'absolute', zIndex: 4, right: 25, top: 100, fontSize: 18, fontWeight:'900',color:'#000' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <span style={{ width: 100    }}>الاســـــــــــــــــــــــم </span><span>: {this.state.items.name}</span>
                                                    </div>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <span style={{ width: 100, letterSpacing: -1 }}>العنوان الوظيفي </span>  <span>: {this.state.items.certificate}</span>
                                                    </div>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <span style={{ width: 100    }}>رقــم الهــــــــوية </span><span>: {this.state.items.s_number}</span>
                                                    </div>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <span style={{ width: 100,   }}>تاريخ الاصــــــــدار </span><span>: {moment(this.state.items.join_date).format('YYYY/MM/DD')}</span>
                                                    </div>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <span style={{ width: 100    }}>تاريخ النفــــــــــاذ </span><span>: {moment(this.state.items.ex_date).format('YYYY/MM/DD')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div style={{ width: '5.06in', height: '3.175in', position: 'relative',  }}>
                                            <div style={{ position: 'absolute', zIndex: 1 }}>
                                                <img src={Ca2} alt='card' style={{ width: '5.06in', height: '3.175in' }} />
                                            </div>
                                            <div style={{ width: '5.06in', height: '3.175in', position: 'absolute', zIndex: 2, display: 'flex' }}>
                                                <div style={{ position: 'absolute', zIndex: 4, right: 34, top: 97 }}>
                                                    <img src={host + 'item/files/' + this.state.items.photo} alt='card' style={{ width: 100, height: 'auto', borderRadius: 10 }} />
                                                </div>
                                                <div style={{ position: 'absolute', zIndex: 4, left: 25, top: 100, fontSize: 18, fontWeight: 'bold' }}>
                                                    <div style={{ textAlign: 'left' }}>
                                                        <span style={{ width: 100 }}>Name </span><span>: {this.state.items.name_en}</span>
                                                    </div>
                                                    <div style={{ textAlign: 'left' }}>
                                                        <span style={{ width: 100 }}>OCC </span>  <span>: {this.state.items.certificate==='طبيب استشاري اشعة'?"Consultant Radiologist":this.state.items.certificate==="طبيب اختصاص اشعة"?"Specialist Radiologist":"GP Radiologist"}</span>
                                                    </div>
                                                    <div style={{ textAlign: 'left' }}>
                                                        <span style={{ width: 100    }}>ID.nO </span><span>: {this.state.items.s_number}</span>
                                                    </div>
                                                    <div style={{ textAlign: 'left' }}>
                                                        <span style={{ width: 100,   }}>REG.Date</span><span>: {moment(this.state.items.join_date).format('YYYY/MM/DD')}</span>
                                                    </div>
                                                    <div style={{ textAlign: 'left' }}>
                                                        <span style={{ width: 100    }}>EXP.Date </span><span>: {moment(this.state.items.ex_date).format('YYYY/MM/DD')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            :
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
                                <Spinner />
                            </div>
                    )

                }
                }

            </Context.Consumer>
        )
    }
}

export default Category;