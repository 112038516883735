import React from 'react';
import Context from '../../assets/js/Context';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton'
import PeopleIcon from '@material-ui/icons/People';
// import SettingsIcon from '@material-ui/icons/Settings';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { NavLink } from 'react-router-dom';
import Users from './usersDash';
import { withRouter } from 'react-router-dom';
import Login from './login';
// import host from '../../assets/js/Host';
// import axios from 'axios';
import Cookies from "universal-cookie";
const cookies = new Cookies();


class ResponsiveDrawer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mobileOpen: false,
        }
    }
    renderPage() {
       
        if (this.props.match.path === '/UsersDash') {
            return (<Users />)
        }
    }
    render(props) {
        const { container } = this.props;

        const handleDrawerToggle = () => {
            this.setState({ mobileOpen: !this.state.mobileOpen });
        };

        const drawer = (
            <div id='BTNSlideContiner'>
                <div>

                    <div id='toolbarSide' />
                    {/* < NavLink activeClassName='ActiveSliderBTN' to='/Orders' >
                    <div>
                        <ListAltIcon id='iconActive' style={{fontSize:25}} />
                        <article id='BTNSlideName'> الطلبات </article>
                    </div>
                </NavLink> */}



                    


                    <NavLink activeClassName='ActiveSliderBTN' to='/UsersDash' >
                        <div id='BTNSlide'>
                            <PeopleIcon id='iconActive' style={{ fontSize: 25 }} />
                            <article id='BTNSlideName'>Users </article>
                        </div>
                    </NavLink>
                    {/* <NavLink activeClassName='ActiveSliderBTN' to='/Drivers' >
                    <div>
                        <DriveEtaIcon id='iconActive' style={{fontSize:25}} />
                        <article id='BTNSlideName'>السواق</article>
                    </div>
                </NavLink> */}



                    {/* < NavLink activeClassName='ActiveSliderBTN' to='/Setting' >
                        <div id='BTNSlide'>
                            <SettingsIcon id='iconActive' style={{ fontSize: 25 }} />
                            <article id='BTNSlideName'>Settings</article>
                        </div>
                    </NavLink> */}
                    <NavLink activeClassName='ActiveSliderBTN' to='/LoginDash' onClick={() => {
                        cookies.remove("tokenAdmin")
                    }}>

                        <div id='BTNSlide' >
                            <MeetingRoomIcon id='iconActive' style={{ fontSize: 25 }} />
                            <article id='BTNSlideName'>sign out</article>
                        </div>
                    </NavLink>

                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    {/* <img src={require('../../assets/img/logo.png')} alt='logo' id='logoSideBar' /> */}
                </div>
            </div>
        );
        return (
            <Context.Consumer>
                {ctx => {
                    if (cookies.get("tokenAdmin")) {
                        return (
                            <div id='rootSidebar'>

                                <Toolbar id='NavContinerApp'>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        id='menuButtonSidebar'
                                    >
                                        <MenuIcon style={{ fontSize: 25 }} />
                                    </IconButton>
                                </Toolbar>
                                <nav id='drawerSidebar' aria-label="mailbox folders">

                                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                                    <Hidden smUp implementation="css">

                                        <Drawer
                                            container={container}
                                            variant="temporary"
                                            anchor='left'
                                            open={this.state.mobileOpen}
                                            onClose={handleDrawerToggle}
                                            id='drawerPaper'
                                            ModalProps={{
                                                keepMounted: true, // Better open performance on mobile.
                                            }}

                                        >
                                            <div id='sideBarbgc' style={{ height: '100vh' }}>

                                                {drawer}
                                            </div>
                                        </Drawer>
                                    </Hidden>

                                    <Hidden xsDown implementation="css">

                                        <Drawer
                                            anchor='left'
                                            id='drawerPaper'
                                            variant="permanent"
                                            open

                                        >
                                            <div id='sideBarbgc' style={{ paddingTop: 30, height: '100vh' }}>

                                                {drawer}
                                            </div>
                                        </Drawer>
                                    </Hidden>
                                </nav>
                                <main id='contentSidebar'>
                                    <div id='toolbarSide' />
                                    {this.renderPage()}
                                </main>
                            </div>
                        )
                    } else {
                        return (
                            <Login />
                        )
                    }
                }
                }
            </Context.Consumer>
        )

    }



}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};
export default withRouter(ResponsiveDrawer)