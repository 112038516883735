/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import Component from "@reactions/component";
import { Pane, Dialog, FilePicker, Spinner } from 'evergreen-ui';
import Context from '../../assets/js/Context';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import "flatpickr/dist/themes/dark.css";
import { Row, Col } from 'react-bootstrap';
import Flatpickr from "react-flatpickr";
import * as moment from "moment";
import Cropper from 'react-easy-crop'
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { getCroppedImg } from '../canvasUtils'
const cookies = new Cookies()
export default function App(props) {
    const [spin, setspin] = useState(true);
    const [Name, setName] = useState(false);
    const [NameEn, setNameEn] = useState(false);
    const [Gender, setGender] = useState(false);
    const [Dob, setDob] = useState();
    const [Pob, setPob] = useState(false);
    const [Email, setEmail] = useState(false);
    const [Emp, setEmp] = useState(false);
    const [Phone, setPhone] = useState(false);
    const [Governorate, setGovernorate] = useState(false);
    const [City, setCity] = useState(false);
    const [Certificate, setCertificate] = useState(false);
    const [Work, setWork] = useState(false);
    const [Photo, setPhoto] = useState([]);
    const [Zain, setZain] = useState([]);
    const [s_number, sets_number] = useState(false);
    const [join_date, setjoin_date] = useState();
    const [ex_date, setex_date] = useState();
    const { handleSubmit } = useForm();
    const [imageSrc, setImageSrc] = React.useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
            )
            console.log('donee', { croppedImage })
            setCroppedImage(croppedImage)



            let url = croppedImage
            const toDataURL = url => fetch(url)
                .then(response => response.blob())
                .then(blob => new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onloadend = () => resolve(reader.result)
                    reader.onerror = reject
                    reader.readAsDataURL(blob)
                }))

            function dataURLtoFile(dataurl, filename) {
                var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, { type: mime });
            }

            toDataURL(url)
                .then(dataUrl => {
                    let arr = []
                    var fileData = dataURLtoFile(dataUrl, "imageName.jpg");
                    arr.push(fileData)
                    setPhoto(arr)

                })



        } catch (e) {
            console.error(e)
        }
    }, [imageSrc, croppedAreaPixels])

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            let imageDataUrl = await readFile(file)



            setImageSrc(imageDataUrl)
        }
    }

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }
    const onregister = (data) => {
        var header = { "Content-Type": "application/x-www-form-urlencoded", };
        let formData = new FormData();

        formData.append("name", Name);
        formData.append("name_en", NameEn);
        formData.append("phone", Phone);
        formData.append("email", Email);
        formData.append("gender", Gender);
        formData.append("dob", Dob);
        formData.append("pob", Pob);
        formData.append("governorate", Governorate);
        formData.append("city", City);
        formData.append("certificate", Certificate);
        formData.append("work", Work);
        formData.append("photo", Photo[0]);
        formData.append("Emp", Emp);
        formData.append("join_date", join_date);
        formData.append("ex_date", ex_date);
        formData.append("s_number", s_number);
        axios({ url: host + `item/${props.match.params.id}`, method: "PUT", data: formData, headers: header })
            .then(res => {
                window.location.href = `/InfoDash/${props.match.params.id}`
            })
            .catch(error => { console.log(error.response.data) })
    }

    useEffect(() => {

        var header = { "Content-Type": "application/json", Authorization: cookies.get("tokenAdmin") };
        axios.get(host + `item/from/${props.match.params.id}`, { headers: header })
            .then(res => {
                console.log(res.data.item);

                setName(res.data.item.name)
                setNameEn(res.data.item.name_en)
                setGender(res.data.item.gender)
                setDob(moment(res.data.item.dob).format('YYYY/MM/DD'))
                setPob(res.data.item.pob)
                setEmail(res.data.item.email)
                setEmp(res.data.item.Emp)
                setPhone(res.data.item.phone)
                setGovernorate(res.data.item.governorate)
                setCity(res.data.item.city)
                setCertificate(res.data.item.certificate)
                setWork(res.data.item.work)
                setPhoto(res.data.item.photo)
                setZain(res.data.item.zain_photo)
                sets_number(res.data.item.s_number)
                setjoin_date(moment(res.data.item.join_date).format('YYYY/MM/DD'))
                setex_date(moment(res.data.item.ex_date).format('YYYY/MM/DD'))
                setspin(false)
            })
            .catch(error => {

                console.log(error.response)
            })
    }, []);



    return (
        <Context.Consumer>

            {ctx => {
                return (
                    !spin ?
                        <div >
                         
                            <div id='AppBarPageContiner'>
                                <div id='AppbarContiner' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                    <Link to={`/InfoDash/${props.match.params.id}`} >
                                        <ArrowBack style={{ color: '#fff', cursor: 'pointer' }} />
                                    </Link>
                                    <div style={{ width: 100, display: 'flex', alignItems: 'center', justifyContent: 'space-around' }} >

                                    </div>
                                </div>
                            </div>
                            <div className="main-block1">

                                <form onSubmit={handleSubmit(onregister)}>
                                    <div className="title">
                                        <i className="fas fa-pencil-alt"></i>
                                        <h2 style={{ color: '#d32c2c', letterSpacing: 0, textAlign: 'center' }}>استمارة انتساب للجمعية العراقية لاطباء الاشعة </h2>
                                    </div>
                                    <div className="info">
                                        <Row style={{ width: '100%', margin: 0 }}>
                                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                                <div className='continerLabelReg'>

                                                    <h5 style={{ letterSpacing: 0 }}>الاسم باللغة العربية</h5>

                                                </div>
                                                <input className="fname" type="text" name="First" placeholder="الاسم باللغة العربية "
                                                    value={Name} onChange={(e) => { setName(e.target.value) }} />

                                            </Col>
                                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                                <div className='continerLabelReg'>

                                                    <h5 style={{ letterSpacing: 0 }}>الاسم بالللغة الانكليزية </h5>
                                                </div>
                                                <input className="fname" type="text" name="Middle" placeholder="مطابق لاسم الجواز الرسمي"
                                                    value={NameEn} onChange={(e) => { setNameEn(e.target.value) }} />

                                            </Col>

                                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                                <div className='continerLabelReg'>

                                                    <h5 style={{ letterSpacing: 0 }}>الجنس</h5>
                                                </div>
                                                <select style={{ padding: 0, height: 44 }} name='Gender'
                                                    onChange={(e) => {
                                                        if (e.target.value !== '') {
                                                            setGender(e.target.value)
                                                        }
                                                    }}
                                                >
                                                    <option value={Gender}>{Gender === 0 ? 'ذكر' : 'انثى'}</option>
                                                    <option value={0}>ذكر</option>
                                                    <option value={1}>انثى</option>
                                                </select>
                                            </Col>
                                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                                <div className='continerLabelReg'>

                                                    <h5 style={{ letterSpacing: 0 }}>محل الولاده</h5>
                                                </div>
                                                <select style={{ padding: 0, height: 44 }} name='محل الولاده'
                                                    onChange={(e) => {
                                                        if (e.target.value !== `${Pob}`) {
                                                            setPob(e.target.value)
                                                        }
                                                    }}
                                                >
                                                    <option value={Pob}>{Pob}</option>
                                                    <option value="بغداد">بغداد</option>
                                                    <option value="البصره">البصره</option>
                                                    <option value="النجف">النجف</option>
                                                    <option value="كربلاء">كربلاء</option>
                                                    <option value="بابل">بابل</option>
                                                    <option value="الموصل">الموصل</option>
                                                    <option value="اربيل">اربيل</option>
                                                    <option value="السليمانيه">السليمانيه</option>
                                                    <option value="دهوك">دهوك</option>
                                                    <option value="كركوك">كركوك</option>
                                                    <option value="صلاح الدين">صلاح الدين</option>
                                                    <option value="الانبار">الانبار</option>
                                                    <option value="ديالى">ديالى</option>
                                                    <option value="ذي قار">ذي قار</option>
                                                    <option value="القادسيه">القادسيه</option>
                                                    <option value="السماوه">السماوه</option>
                                                    <option value="واسط">واسط</option>
                                                    <option value="ميسان">ميسان</option>
                                                </select>
                                            </Col>
                                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                                <div className='continerLabelReg'>

                                                    <h5 style={{ letterSpacing: 0 }}>تاريخ الولاده</h5>
                                                </div>
                                                <Flatpickr name='تارخ الولاده'
                                                    value={Dob}
                                                    onChange={(date) => {
                                                        setDob(date)
                                                    }}
                                                />
                                            </Col>
                                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                                <div className='continerLabelReg'>

                                                    <h5 style={{ letterSpacing: 0 }}>العنوان الوظيفي</h5>
                                                </div>
                                                <select style={{ padding: 0, height: 44 }} name='العنوان الوظيفي'
                                                    onChange={(e) => {
                                                        if (e.target.value !== `${Certificate}`) {
                                                            setCertificate(e.target.value)
                                                        }
                                                    }}
                                                >
                                                    <option value={Certificate}>{Certificate}</option>
                                                    <option value="طبيب استشاري اشعة">طبيب استشاري اشعة</option>
                                                    <option value="طبيب اختصاص اشعة">طبيب اختصاص اشعة</option>
                                                    <option value="طبيب ممارس اشعة">طبيب ممارس اشعة</option>
                                                </select>
                                            </Col>
                                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                                <div className='continerLabelReg'>

                                                    <h5 style={{ letterSpacing: 0 }}>مكان العمل الحالي</h5>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <select name='مكان العمل الحالي'
                                                        onChange={(e) => {
                                                            if (e.target.value !== `${Work}`) {
                                                                setWork(e.target.value)
                                                            }
                                                        }}
                                                    >
                                                        <option value={Work}>{Work}</option>
                                                        <option value="بغداد">بغداد</option>
                                                        <option value="البصره">البصره</option>
                                                        <option value="النجف">النجف</option>
                                                        <option value="كربلاء">كربلاء</option>
                                                        <option value="بابل">بابل</option>
                                                        <option value="الموصل">الموصل</option>
                                                        <option value="اربيل">اربيل</option>
                                                        <option value="السليمانيه">السليمانيه</option>
                                                        <option value="دهوك">دهوك</option>
                                                        <option value="كركوك">كركوك</option>
                                                        <option value="صلاح الدين">صلاح الدين</option>
                                                        <option value="الانبار">الانبار</option>
                                                        <option value="ديالى">ديالى</option>
                                                        <option value="ذي قار">ذي قار</option>
                                                        <option value="القادسيه">القادسيه</option>
                                                        <option value="السماوه">السماوه</option>
                                                        <option value="واسط">واسط</option>
                                                        <option value="ميسان">ميسان</option>
                                                    </select>

                                                </div>
                                            </Col>
                                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                                <div className='continerLabelReg'>

                                                    <h5 style={{ letterSpacing: 0 }}>عنوان السكن</h5>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <select style={{ padding: 0, height: 44, width: 140, marginLeft: 10 }} name='عنوان السكن'
                                                        onChange={(e) => {
                                                            if (e.target.value !== `${Governorate}`) {
                                                                setGovernorate(e.target.value)
                                                            }
                                                        }}
                                                    >
                                                        <option value={Governorate}>{Governorate}</option>
                                                        <option value="بغداد">بغداد</option>
                                                        <option value="البصره">البصره</option>
                                                        <option value="النجف">النجف</option>
                                                        <option value="كربلاء">كربلاء</option>
                                                        <option value="بابل">بابل</option>
                                                        <option value="الموصل">الموصل</option>
                                                        <option value="اربيل">اربيل</option>
                                                        <option value="السليمانيه">السليمانيه</option>
                                                        <option value="دهوك">دهوك</option>
                                                        <option value="كركوك">كركوك</option>
                                                        <option value="صلاح الدين">صلاح الدين</option>
                                                        <option value="الانبار">الانبار</option>
                                                        <option value="ديالى">ديالى</option>
                                                        <option value="ذي قار">ذي قار</option>
                                                        <option value="القادسيه">القادسيه</option>
                                                        <option value="السماوه">السماوه</option>
                                                        <option value="واسط">واسط</option>
                                                        <option value="ميسان">ميسان</option>
                                                    </select>
                                                    <input style={{ marginRight: 10, height: 44, width: 140 }} className="fname" type="text" name="First" placeholder="مدينة "
                                                        value={City} onChange={(e) => { setCity(e.target.value) }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                                <div className='continerLabelReg'>

                                                    <h5 style={{ letterSpacing: 0 }}>رقم الهاتف</h5>
                                                </div>
                                                <input type="number" name="Phone" placeholder="رقم الهاتف"
                                                    value={Phone} onChange={(e) => { setPhone(e.target.value) }}
                                                />
                                            </Col>
                                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                                <div className='continerLabelReg'>


                                                    <h5 style={{ letterSpacing: 0 }}>البريد الالكتروني</h5>
                                                </div>
                                                <input type="text" name="Email" placeholder="البريد الالكتروني"
                                                    value={Email} onChange={(e) => { setEmail(e.target.value) }}
                                                />
                                            </Col>

                                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                                <div className='continerLabelReg'>

                                                    <h5 style={{ letterSpacing: 0 }}>صوره شخصية</h5>
                                                </div>

                                                <Component initialState={{ isShown: false, imgg: [] }}>
                                                    {({ state, setState }) => (
                                                        <Pane >
                                                            <Dialog
                                                                isShown={state.isShown}
                                                                hasHeader={false}
                                                                hasFooter={false}
                                                                onCloseComplete={() => setState({ isShown: false })}


                                                                onConfirm={() => {
                                                                    setState({ isShown: false })
                                                                }}
                                                            >
                                                                <React.Fragment>
                                                                    <div style={{ height: 360, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', position: 'relative' }}>


                                                                        <Cropper
                                                                            image={imageSrc}
                                                                            crop={crop}
                                                                            zoom={zoom}
                                                                            cropSize={{ width: 261, height: 337 }}
                                                                            onCropChange={setCrop}
                                                                            onZoomChange={setZoom}
                                                                            onCropComplete={onCropComplete}
                                                                            onMediaLoaded={(mediaSize) => {
                                                                                // Adapt zoom based on media size to fit max height
                                                                                // onZoomChange(CONTAINER_HEIGHT / mediaSize.naturalHeight)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div style={{ height: 60, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                        <div id='PMbUtton' onClick={() => setZoom(zoom + 0.25)}>+</div>
                                                                        <div id='PMbUtton' onClick={() => setZoom(zoom - 0.25)}>-</div>
                                                                    </div>
                                                                    <div style={{ height: 60, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                        <div id='PMbUtton' style={{ backgroundColor: '#2457d9a8', width: 100 }}
                                                                            onClick={() => {
                                                                                showCroppedImage()
                                                                                setState({ isShown: false })

                                                                            }}>حفظ</div>
                                                                    </div>
                                                                </React.Fragment>
                                                            </Dialog>
                                                            <input type="file" id='txtConfirmPassword' style={{ backgroundColor: '#fff' }} placeholder='صوره شخصيه'
                                                                onChange={(e) => {
                                                                    setState({ isShown: true })
                                                                    onFileChange(e)
                                                                }} accept="image/*" />

                                                        </Pane>
                                                    )}
                                                </Component>

                                            </Col>
                                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                            <div className='continerLabelReg'>

                                                <h5 style={{ letterSpacing: 0 ,textAlign:'center'}}>هل انت عضو ضمن الجمعية العراقية<br/> لاطباء الاشعة والتصوير الطبي؟</h5>
                                            </div>
                                            <select style={{ padding: 0, height: 44, backgroundColor: '#fff' }} name='Gender'
                                                onChange={(e) => {
                                                    if (e.target.value !== '') {
                                                        setEmp(e.target.value)
                                                    }
                                                }}
                                            >
                                                <option value={Emp}>{Emp === 0 ? 'نعم' : 'كلا'}</option>
                                                <option value={0}>نعم</option>
                                                <option value={1}>كلا</option>
                                            </select>
                                        </Col>


                                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                                <div className='continerLabelReg'>

                                                    <h5 style={{ letterSpacing: 0 }}>رقم الهوية</h5>
                                                </div>
                                                <input type="number" name="Phone" placeholder="رقم الهوية"
                                                    value={s_number} onChange={(e) => { sets_number(e.target.value) }}
                                                />
                                            </Col>
                                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                                <div className='continerLabelReg'>

                                                    <h5 style={{ letterSpacing: 0 }}>تاريخ الانتساب</h5>
                                                </div>
                                                <Flatpickr name='تارخ الانتساب'
                                                    value={join_date}
                                                    onChange={(date) => {
                                                        setjoin_date(date)
                                                    }}
                                                />
                                            </Col>
                                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                                <div className='continerLabelReg'>

                                                    <h5 style={{ letterSpacing: 0 }}>تاريخ النفاذ</h5>
                                                </div>
                                                <Flatpickr name='تارخ النفاذ'
                                                    value={ex_date}
                                                    onChange={(date) => {
                                                        setex_date(date)
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <div>
                                            <button style={{ width: '100%', marginTop: 40 }} type="submit" onClick={() => {

                                                handleSubmit(onregister)

                                            }}>تعديل</button>
                                        </div>

                                    </div>
                                    {/* <div className="checkbox">
                                    <input type="checkbox" name="checkbox" /><span>I agree to the <a href="https://www.w3docs.com/privacy-policy">Privacy Poalicy for W3Docs.</a></span>
                                </div> */}


                                </form>
                            </div>
                        </div>
                        : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
                            <Spinner />
                        </div>
                )
            }
            }
        </Context.Consumer>

    );
}