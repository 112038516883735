import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Context from '../../assets/js/Context';
import qs from 'qs';
// import { Link } from 'react-router-dom';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
const cookies = new Cookies();

export default function App() {
    const [username, setusername] = useState(false);
    const [password, setpassword] = useState(false);
    const {  handleSubmit } = useForm();

   
    const signin = (data) => {
        axios.post(host + `auth/login`,
            qs.stringify({
                phone: username,
                password:password,
            }), {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }).then(response => {
            console.log(response.data);
                cookies.set("tokenAdmin", response.data.token, { expires: new Date(Date.now() + 604800000) });
                window.location.href = "/UsersDash";
        })
            .catch(error => {
                console.log(error.response.data);
            });
    }


    return (
        <Context.Consumer>

            {ctx => {
                return (
                    <div className="main-block1">
                        <div className="main-block" >
                          
                            <form onSubmit={handleSubmit(signin)} 
                            style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                                <div className="title">
                                    <i className="fas fa-pencil-alt"></i>
                                    <h4 style={{ color: '#d32c2c',letterSpacing:0,fontFamily:'Careem' }}>تسجيل الدخول الى لوحة التحكم</h4>
                                </div>
                                
                                <div  className="info" style={{ display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center' }}>
                                    <div className='continerLabelReg'>
                                        <h5 style={{letterSpacing:0,fontFamily:'Careem'}}>رقم الهاتف</h5>
                                       
                                    </div>
                                    <input style={{textAlign:'center'}} className="fname" type="text" name="رقم الهاتف" placeholder=" رقم الهاتف"  onChange={(e) => setusername(e.target.value)} />
                                    <div className='continerLabelReg'>
                                        <h5 style={{letterSpacing:0,fontFamily:'Careem'}}>كلمة المرور</h5>
                                      
                                    </div>
                                    <input style={{textAlign:'center'}} className="fname" type="password" name="كلمة المرور" placeholder=" كلمة المرور"  onChange={(e) => setpassword(e.target.value)} />
                                </div>
                                <button style={{width:300}} type="submit" onClick={() => handleSubmit(signin)}>تسجيل الدخول</button>
                            
                            </form>
                        </div>
                    </div>

                )
            }
            }
        </Context.Consumer>

    );
}