
import React from 'react';
import Context from '../assets/js/Context';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
class Office extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    return (

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
                          <CheckCircleIcon style={{color:'green',marginRight:5,height:35,width:35}}/>  <h4 style={{letterSpacing:0}}>تم ارسال المعلومات بنجاح</h4> 
                        </div>
                    );

                }
                }
            </Context.Consumer>
        )
    }

}
export default Office
