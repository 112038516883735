import React from 'react';
import ScrollToTop from './assets/js/scrolToTop';
import Context from './assets/js/Context';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NotFound from './assets/js/NotFound';
import LoginDash from './component/dash/login';
import Info from './component/dash/info';
import PrintCard from './component/dash/printCard';
import Register from './component/register';
import EditDash from './component/dash/edit';
// import Cookies from "universal-cookie";
import Done from './component/done';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/sidebar.css';
import Dash from './component/dash/sidebar';
// const cookies = new Cookies();

class App extends React.Component {
  constructor() {
    super();
    this.state = {

    }
  }
  render() {
    return (
      <div >
        <Context.Provider value={{
          value: this.state, action: {
            // AddItem: ( value) => {
            //   this.setState({updateData:value})
            // },
          }
        }}>
          <div className="App">
            <BrowserRouter>
              <ScrollToTop>
                <Switch>
                  <Route exact path='/' component={Register} />
                  <Route path='/UsersDash' component={Dash} />
                  <Route path='/GamesDash' component={Dash} />
                  <Route path='/LoginDash' component={LoginDash} />
                  <Route path='/Done' component={Done} />
                  <Route path='/InfoDash/:id' component={Info} />
                  <Route path='/PrintCard/:id' component={PrintCard} />
                  <Route path='/EditDash/:id' component={EditDash} />
                  <Route exact={true} path='*' component={NotFound} />
                </Switch>
              </ScrollToTop>
            </BrowserRouter>
          </div>
        </Context.Provider>

      </div>
    );
  }
}

export default App;
