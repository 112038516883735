/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useState, useCallback } from "react";

import Component from "@reactions/component";
import { Pane, Dialog, FilePicker, Spinner } from 'evergreen-ui';
import Context from '../assets/js/Context';
import Logo from '../assets/images/logo.png';
import "flatpickr/dist/themes/dark.css";
import { Row, Col } from 'react-bootstrap';
import Flatpickr from "react-flatpickr";
// import * as moment from "moment";
import Cropper from 'react-easy-crop'
// import Cookies from "universal-cookie";
import host from '../assets/js/Host';
import axios from 'axios';
// const cookies = new Cookies()
import { getCroppedImg } from './canvasUtils'
export default function App() {
    const [Name, setName] = useState(false);
    const [NameEn, setNameEn] = useState(false);
    const [Gender, setGender] = useState(false);
    const [Dob, setDob] = useState();
    const [Pob, setPob] = useState(false);
    const [Email, setEmail] = useState(false);
    const [Emp, setEmp] = useState(false);
    const [Phone, setPhone] = useState(false);
    const [Governorate, setGovernorate] = useState(false);
    const [City, setCity] = useState(false);
    const [Certificate, setCertificate] = useState(false);
    const [Work, setWork] = useState(false);
    const [Photo, setPhoto] = useState([]);
    const [Zain, setZain] = useState([]);
    const [imageSrc, setImageSrc] = React.useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [roppedImage, setCroppedImage] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
            )
            console.log('donee', { croppedImage })
            setCroppedImage(croppedImage)




            const toDataURL = croppedImage => fetch(croppedImage)
                .then(response => response.blob())
                .then(blob => new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onloadend = () => resolve(reader.result)
                    reader.onerror = reject
                    reader.readAsDataURL(blob)
                }))

            function dataURLtoFile(dataurl, filename) {
                var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, { type: mime });
            }

            toDataURL(croppedImage)
                .then(dataUrl => {
                    let arr = []
                    var fileData = dataURLtoFile(dataUrl, "imageName.jpg");
                    arr.push(fileData)
                    setPhoto(arr)

                })



        } catch (e) {
            console.error(e)
        }
    }, [imageSrc, croppedAreaPixels])

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            let imageDataUrl = await readFile(file)



            setImageSrc(imageDataUrl)
        }
    }

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    const onregister = (data) => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        document.getElementById('BTNSS').style.display = 'none';
        document.getElementById('BTNSS1').style.display = 'flex';
        if (!Name) {
            alert('يرجى ادخال الاسم الثلاثي')
            document.getElementById('BTNSS').style.display = 'flex';
            document.getElementById('BTNSS1').style.display = 'none';
        }
        else if (!NameEn) {
            alert('يرجى ادخال الاسم باللغه الانكليزيه')
            document.getElementById('BTNSS').style.display = 'flex';
            document.getElementById('BTNSS1').style.display = 'none';
        }
        else if (!Gender) {
            alert('يرجى اختيار الجنس')
            document.getElementById('BTNSS').style.display = 'flex';
            document.getElementById('BTNSS1').style.display = 'none';
        }
        else if (!Pob) {
            alert('يرجى اختيار محل الولاده')
            document.getElementById('BTNSS').style.display = 'flex';
            document.getElementById('BTNSS1').style.display = 'none';
        }
        else if (!Dob) {
            alert('يرجى تحديد تاريخ الولاده')
            document.getElementById('BTNSS').style.display = 'flex';
            document.getElementById('BTNSS1').style.display = 'none';
        }
        else if (!Certificate) {
            alert('يرجى اختيار العنوان الوظيفي')
            document.getElementById('BTNSS').style.display = 'flex';
            document.getElementById('BTNSS1').style.display = 'none';
        }
        else if (!Work) {
            alert('يرجى اختيار مكان العمل الحالي')
            document.getElementById('BTNSS').style.display = 'flex';
            document.getElementById('BTNSS1').style.display = 'none';
        }
        else if (!Governorate || !City) {
            alert('يرجى اختيار عنوان السكن')
            document.getElementById('BTNSS').style.display = 'flex';
            document.getElementById('BTNSS1').style.display = 'none';
        }
        else if (!Phone) {
            alert('يرجى ادخال رقم الهاتف')
            document.getElementById('BTNSS').style.display = 'flex';
            document.getElementById('BTNSS1').style.display = 'none';
        }
        else if (Phone.length < 11) {
            alert('رقم الهاتف غير صحيح')
            document.getElementById('BTNSS').style.display = 'flex';
            document.getElementById('BTNSS1').style.display = 'none';
        }
        else if (!Email) {
            alert('يرجى ادخال البريد الالكتروني ')
            document.getElementById('BTNSS').style.display = 'flex';
            document.getElementById('BTNSS1').style.display = 'none';
        }
        else if (!Email || regex.test(Email) === false) {
            alert('البريد الالكتروني غير صحيح')
            document.getElementById('BTNSS').style.display = 'flex';
            document.getElementById('BTNSS1').style.display = 'none';

        }
        else if (Photo.length < 1) {
            alert('يرجى اختيار صوره شخصيه')
            document.getElementById('BTNSS').style.display = 'flex';
            document.getElementById('BTNSS1').style.display = 'none';
        }

        else {
            var header = { "Content-Type": "application/x-www-form-urlencoded", };
            let formData = new FormData();

            formData.append("name", Name);
            formData.append("name_en", NameEn);
            formData.append("phone", Phone);
            formData.append("email", Email);
            formData.append("gender", Gender);
            formData.append("dob", Dob);
            formData.append("pob", Pob);
            formData.append("governorate", Governorate);
            formData.append("city", City);
            formData.append("certificate", Certificate);
            formData.append("work", Work);
            formData.append("photo", Photo[0]);
            formData.append("Emp", Emp);
            axios({ url: host + `item/`, method: "POST", data: formData, headers: header })
                .then(res => {
                    document.getElementById('BTNSS').style.display = 'flex';
                    document.getElementById('BTNSS1').style.display = 'none';
                    window.location.href = '/Done'
                })
                .catch(error => {
                    if (error.response.data.message === 'رقم الهاتف مستخدم مسبقآ او تم تقديم المعلومات ') {
                        alert('رقم الهاتف مستخدم مسبقآ او تم تقديم المعلومات')
                        document.getElementById('BTNSS').style.display = 'flex';
                        document.getElementById('BTNSS1').style.display = 'none';
                    }
                    else {
                        document.getElementById('BTNSS').style.display = 'flex';
                        document.getElementById('BTNSS1').style.display = 'none';
                        console.log(error.response.data)
                    }
                })
        }
    }

    // useEffect(() => {
    //     var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
    //     axios.get(host + `admin/games/get/all`, { headers: header })
    //         .then(res => {
    //             // console.log(res.data.data.games);
    //             setgames(res.data.data.games)
    //             // console.log(arr);
    //         })
    //         .catch(error => { console.log(error.response) })
    // }, []);


    return (
        <Context.Consumer>

            {ctx => {
                return (
                    <div >
                        <div className="left-part">
                            <img src={Logo} alt=" " style={{ width: 180 }} />

                        </div>
                        <div className="main-block1">

                            <div id='form' >
                                <div className="title">
                                    <i className="fas fa-pencil-alt"></i>
                                    <h2 style={{ color: '#d32c2c', letterSpacing: 0, textAlign: 'center' }}>استمارة انتساب للجمعية العراقية لاطباء الاشعة </h2>
                                </div>
                                <div className="info">
                                    <Row style={{ width: '100%', margin: 0 }}>
                                        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                            <div className='continerLabelReg'>

                                                <h5 style={{ letterSpacing: 0 }}>الاسم باللغة العربية</h5>

                                            </div>
                                            <input style={{ height: 44, backgroundColor: '#fff' }} className="fname" type="text" name="First" placeholder="الاسم باللغة العربية "
                                                onChange={(e) => { setName(e.target.value) }} />

                                        </Col>
                                        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                            <div className='continerLabelReg'>

                                                <h5 style={{ letterSpacing: 0 }}>الاسم بالللغة الانكليزية </h5>
                                            </div>
                                            <input style={{ height: 44, backgroundColor: '#fff' }} className="fname" type="text" name="Middle" placeholder="مطابق لاسم الجواز الرسمي"
                                                onChange={(e) => { setNameEn(e.target.value) }} />

                                        </Col>

                                        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                            <div className='continerLabelReg'>

                                                <h5 style={{ letterSpacing: 0 }}>الجنس</h5>
                                            </div>
                                            <select style={{ padding: 0, height: 44, backgroundColor: '#fff' }} name='Gender'
                                                onChange={(e) => {
                                                    if (e.target.value !== '') {
                                                        setGender(e.target.value)
                                                    }
                                                }}
                                            >
                                                <option value=''>اختيار</option>
                                                <option value={0}>ذكر</option>
                                                <option value={1}>انثى</option>
                                            </select>
                                        </Col>
                                        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                            <div className='continerLabelReg'>

                                                <h5 style={{ letterSpacing: 0 }}>محل الولاده</h5>
                                            </div>
                                            <select style={{ padding: 0, height: 44, backgroundColor: '#fff' }} name='محل الولاده'
                                                onChange={(e) => {
                                                    if (e.target.value !== '') {
                                                        setPob(e.target.value)
                                                    }
                                                }}
                                            >
                                                <option value=''>اختيار</option>
                                                <option value="بغداد">بغداد</option>
                                                <option value="البصره">البصره</option>
                                                <option value="النجف">النجف</option>
                                                <option value="كربلاء">كربلاء</option>
                                                <option value="بابل">بابل</option>
                                                <option value="الموصل">الموصل</option>
                                                <option value="اربيل">اربيل</option>
                                                <option value="السليمانيه">السليمانيه</option>
                                                <option value="دهوك">دهوك</option>
                                                <option value="كركوك">كركوك</option>
                                                <option value="صلاح الدين">صلاح الدين</option>
                                                <option value="الانبار">الانبار</option>
                                                <option value="ديالى">ديالى</option>
                                                <option value="ذي قار">ذي قار</option>
                                                <option value="القادسيه">القادسيه</option>
                                                <option value="السماوه">السماوه</option>
                                                <option value="واسط">واسط</option>
                                                <option value="ميسان">ميسان</option>
                                            </select>
                                        </Col>
                                        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                            <div className='continerLabelReg'>

                                                <h5 style={{ letterSpacing: 0 }}>تاريخ الولاده</h5>
                                            </div>
                                            <Flatpickr name='تارخ الولاده'
                                                value={Dob}
                                                onChange={(date) => {
                                                    setDob(date)
                                                }}
                                            />
                                        </Col>
                                        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                            <div className='continerLabelReg'>

                                                <h5 style={{ letterSpacing: 0 }}>العنوان الوظيفي</h5>
                                            </div>
                                            <select style={{ padding: 0, height: 44, backgroundColor: '#fff' }} name='العنوان الوظيفي'
                                                onChange={(e) => {
                                                    if (e.target.value !== '') {
                                                        setCertificate(e.target.value)
                                                    }
                                                }}
                                            >
                                                <option value=''>اختيار</option>
                                                <option value="طبيب استشاري اشعة">طبيب استشاري اشعة</option>
                                                <option value="طبيب اختصاص اشعة">طبيب اختصاص اشعة</option>
                                                <option value="طبيب ممارس اشعة">طبيب ممارس اشعة</option>
                                            </select>
                                        </Col>
                                        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                            <div className='continerLabelReg'>

                                                <h5 style={{ letterSpacing: 0 }}>مكان العمل الحالي</h5>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <select style={{ padding: 0, height: 44, backgroundColor: '#fff' }} name='مكان العمل الحالي'
                                                    onChange={(e) => {
                                                        if (e.target.value !== '') {
                                                            setWork(e.target.value)
                                                        }
                                                    }}
                                                >
                                                    <option value=''>محافظة</option>
                                                    <option value="بغداد">بغداد</option>
                                                    <option value="البصره">البصره</option>
                                                    <option value="النجف">النجف</option>
                                                    <option value="كربلاء">كربلاء</option>
                                                    <option value="بابل">بابل</option>
                                                    <option value="الموصل">الموصل</option>
                                                    <option value="اربيل">اربيل</option>
                                                    <option value="السليمانيه">السليمانيه</option>
                                                    <option value="دهوك">دهوك</option>
                                                    <option value="كركوك">كركوك</option>
                                                    <option value="صلاح الدين">صلاح الدين</option>
                                                    <option value="الانبار">الانبار</option>
                                                    <option value="ديالى">ديالى</option>
                                                    <option value="ذي قار">ذي قار</option>
                                                    <option value="القادسيه">القادسيه</option>
                                                    <option value="السماوه">السماوه</option>
                                                    <option value="واسط">واسط</option>
                                                    <option value="ميسان">ميسان</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                            <div className='continerLabelReg'>

                                                <h5 style={{ letterSpacing: 0 }}>عنوان السكن</h5>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <select style={{ padding: 0, height: 44, width: 140, marginLeft: 10 }} name='عنوان السكن'
                                                    onChange={(e) => {
                                                        if (e.target.value !== '') {
                                                            setGovernorate(e.target.value)
                                                        }
                                                    }}
                                                >
                                                    <option value=''>محافظة</option>
                                                    <option value="بغداد">بغداد</option>
                                                    <option value="البصره">البصره</option>
                                                    <option value="النجف">النجف</option>
                                                    <option value="كربلاء">كربلاء</option>
                                                    <option value="بابل">بابل</option>
                                                    <option value="الموصل">الموصل</option>
                                                    <option value="اربيل">اربيل</option>
                                                    <option value="السليمانيه">السليمانيه</option>
                                                    <option value="دهوك">دهوك</option>
                                                    <option value="كركوك">كركوك</option>
                                                    <option value="صلاح الدين">صلاح الدين</option>
                                                    <option value="الانبار">الانبار</option>
                                                    <option value="ديالى">ديالى</option>
                                                    <option value="ذي قار">ذي قار</option>
                                                    <option value="القادسيه">القادسيه</option>
                                                    <option value="السماوه">السماوه</option>
                                                    <option value="واسط">واسط</option>
                                                    <option value="ميسان">ميسان</option>
                                                </select>
                                                <input style={{ marginRight: 10, height: 44, width: 140, backgroundColor: '#fff' }} className="fname" type="text" name="First" placeholder="مدينة "
                                                    onChange={(e) => { setCity(e.target.value) }}
                                                />
                                            </div>
                                        </Col>
                                        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                            <div className='continerLabelReg'>

                                                <h5 style={{ letterSpacing: 0 }}>رقم الهاتف</h5>
                                            </div>
                                            <input style={{ padding: 0, height: 44, backgroundColor: '#fff' }} type="number" name="Phone" placeholder="رقم الهاتف"
                                                onChange={(e) => { setPhone(e.target.value) }}
                                            />
                                        </Col>
                                        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                            <div className='continerLabelReg'>


                                                <h5 style={{ letterSpacing: 0 }}>البريد الالكتروني</h5>
                                            </div>
                                            <input style={{ padding: 0, height: 44, backgroundColor: '#fff' }} type="text" name="Email" placeholder="البريد الالكتروني"
                                                onChange={(e) => { setEmail(e.target.value) }}
                                            />
                                        </Col>

                                        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                            <div className='continerLabelReg'>

                                                <h5 style={{ letterSpacing: 0 }}>صوره شخصية</h5>
                                            </div>

                                            <Component initialState={{ isShown: false, imgg: [] }}>
                                                {({ state, setState }) => (
                                                    <Pane >
                                                        <Dialog
                                                            isShown={state.isShown}
                                                            hasHeader={false}
                                                            hasFooter={false}
                                                            onCloseComplete={() => setState({ isShown: false })}


                                                            onConfirm={() => {
                                                                setState({ isShown: false })
                                                            }}
                                                        >
                                                            <React.Fragment>
                                                                <div style={{ height: 360, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', position: 'relative' }}>


                                                                    <Cropper
                                                                        image={imageSrc}
                                                                        crop={crop}
                                                                        zoom={zoom}
                                                                        cropSize={{ width: 261, height: 337 }}
                                                                        onCropChange={setCrop}
                                                                        onZoomChange={setZoom}
                                                                        onCropComplete={onCropComplete}
                                                                        onMediaLoaded={(mediaSize) => {
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div style={{ height: 60, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                    <div id='PMbUtton' onClick={() => setZoom(zoom + 0.25)}>+</div>
                                                                    <div id='PMbUtton' onClick={() => setZoom(zoom - 0.25)}>-</div>
                                                                </div>
                                                                <div style={{ height: 60, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                    <div id='PMbUtton' style={{ backgroundColor: '#2457d9a8', width: 100 }}
                                                                        onClick={() => {
                                                                            showCroppedImage()
                                                                            setState({ isShown: false })
                                                                        }}>حفظ</div>
                                                                </div>
                                                            </React.Fragment>
                                                        </Dialog>
                                                        <input type="file" id='txtConfirmPassword' style={{ backgroundColor: '#fff' }} placeholder='صوره شخصيه'
                                                            onChange={(e) => {
                                                                setState({ isShown: true })
                                                                onFileChange(e)
                                                            }} accept="image/*" />

                                                    </Pane>
                                                )}
                                            </Component>

                                        </Col>
                                        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifayContent: 'center' }} sm={12} md={6} lg={4} >
                                            <div className='continerLabelReg'>

                                                <h5 style={{ letterSpacing: 0 ,textAlign:'center'}}>هل انت عضو ضمن الجمعية العراقية<br/> لاطباء الاشعة والتصوير الطبي؟</h5>
                                            </div>
                                            <select style={{ padding: 0, height: 44, backgroundColor: '#fff' }} name='Gender'
                                                onChange={(e) => {
                                                    if (e.target.value !== 'select') {
                                                        setEmp(e.target.value)
                                                    }
                                                }}
                                            >
                                                <option value='select'>اختيار</option>
                                                <option value={0}>نعم</option>
                                                <option value={1}>كلا</option>
                                            </select>
                                        </Col>
                                    </Row>
                                    <div>
                                        <button id="BTNSS" style={{ width: '100%', marginTop: 40, alignItems: 'center', justifyContent: 'center' }} type="submit" onClick={() => {

                                            onregister()

                                        }}>ارسال</button>

                                        <button id="BTNSS1" style={{ width: '100%', marginTop: 40, display: 'none', alignItems: 'center', justifyContent: 'center' }} ><Spinner /></button>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>

                )
            }
            }
        </Context.Consumer>

    );
}