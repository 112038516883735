
import React from 'react';
import Context from '../../assets/js/Context';
import { Spinner } from 'evergreen-ui';
import { Col, Row } from 'react-bootstrap';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import qs from 'qs';
import { Link } from 'react-router-dom';
import Component from "@reactions/component";
import { Pane, Dialog } from 'evergreen-ui';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteForever from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import Prints from '@material-ui/icons/Print';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/dark.css";
// import { html2canvas } from 'html2canvas'
import * as moment from "moment";
const cookies = new Cookies();

class Office extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            shipment: [],
            status_shipment: [],
            redirect: false,
            returnTo: localStorage.getItem('RETurnTo'),
            spinPage: true,
        }
    }
    componentDidMount() {
        var header = { "Content-Type": "application/json", Authorization: cookies.get("tokenAdmin") };
        axios.get(host + `item/from/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                console.log(res.data.item);
                this.setState({
                    items: res.data.item,
                    spinPage: false
                });
            })
            .catch(error => {

                console.log(error.response)
            })
    }

    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    return (
                        !this.state.spinPage ?
                            <div id='infoShipPdfPage'>
                                <div id='AppBarPageContiner'>
                                    <div id='AppbarContiner' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                        <Link to={`/UsersDash`} >
                                            <ArrowBack style={{ color: '#fff', cursor: 'pointer' }} />
                                        </Link>
                                        <div style={{ width: 100, display: 'flex', alignItems: 'center', justifyContent: 'space-around' }} >
                                        <div>
                                                {this.state.items.status === 2 ?
                                                    <Component initialState={{ isShown: false }}>
                                                        {({ state, setState }) => (
                                                            <Pane>
                                                                <Dialog
                                                                    isShown={state.isShown}
                                                                    title="حذف الطلب"
                                                                    intent="danger"
                                                                    onCloseComplete={() => setState({ isShown: false })}
                                                                    confirmLabel="تاكيد"
                                                                    cancelLabel="الغاء"
                                                                    onConfirm={() => {
                                                                        axios.delete(host + `item/${this.props.match.params.id}`,
                                                                            qs.stringify({}), {
                                                                            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenAdmin") }
                                                                        }).then(response => {
                                                                            this.setState({ nameCate: '', BtnAdd: false })
                                                                           window.location.href='/UsersDash'
                                                                            this.componentDidMount()
                                                                        }).catch(error => { console.log(error) })
                                                                        setState({ isShown: false })
                                                                    }}
                                                                >
                                                                    هل انت متاكد من عملية الحذف
                                                                </Dialog>
                                                                <DeleteForever style={{ color: '#d26a74', cursor: 'pointer' }} onClick={() => setState({ isShown: true })} />
                                                            </Pane>
                                                        )}
                                                    </Component>
                                                    : null
                                                }
                                            </div>
                                            <div>

                                                <Link to={`/EditDash/${this.props.match.params.id}`} >
                                                    <EditIcon style={{ color: '#eee', cursor: 'pointer' }} />
                                                </Link>
                                            </div>
                                            <div>
                                                {this.state.items.status === 0 ?
                                                    <Component initialState={{ isShown: false }}>
                                                        {({ state, setState }) => (
                                                            <Pane>
                                                                <Dialog
                                                                    isShown={state.isShown}
                                                                    title="رفض الطلب"
                                                                    intent="danger"
                                                                    onCloseComplete={() => setState({ isShown: false })}
                                                                    confirmLabel="تاكيد"
                                                                    cancelLabel="الغاء"
                                                                    onConfirm={() => {
                                                                        axios.post(host + `item/rejected/${this.props.match.params.id}`,
                                                                            qs.stringify({}), {
                                                                            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenAdmin") }
                                                                        }).then(response => {
                                                                            this.setState({ nameCate: '', BtnAdd: false })
                                                                            toast('تم رفض الطلب ', {
                                                                                position: "bottom-center",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: true
                                                                            });
                                                                            this.componentDidMount()
                                                                        }).catch(error => { console.log(error) })
                                                                        setState({ isShown: false })
                                                                    }}
                                                                >
                                                                    هل انت متاكد من عملية رفض الطلب
                                                                </Dialog>
                                                                <CancelIcon style={{ color: '#d26a74', cursor: 'pointer' }} onClick={() => setState({ isShown: true })} />
                                                            </Pane>
                                                        )}
                                                    </Component>
                                                    : null
                                                }
                                            </div>
                                            <div>
                                                {this.state.items.status === 0 ?
                                                    <Component initialState={{ isShown: false, s_number: '', join_date: '', ex_date: '' }}>
                                                        {({ state, setState }) => (
                                                            <Pane >
                                                                <Dialog
                                                                    isShown={state.isShown}
                                                                    hasHeader={false}

                                                                    onCloseComplete={() => setState({ isShown: false })}
                                                                    confirmLabel="طباعة"
                                                                    cancelLabel="الغاء"

                                                                    onConfirm={() => {
                                                                        setState({ isShown: false })
                                                                        axios.post(host + `item/print/${this.props.match.params.id}`,
                                                                            qs.stringify({ s_number: state.s_number, join_date: state.join_date, ex_date: state.ex_date }), {
                                                                            headers: {
                                                                                "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenAdmin")
                                                                            }
                                                                        }).then(response => {
                                                                            window.location.href = `/PrintCard/${this.props.match.params.id}`
                                                                            this.componentDidMount();

                                                                        }).catch(error => { console.log(error) })
                                                                    }}
                                                                >
                                                                    <div style={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                                        <div className='continerLabelReg'>

                                                                            <h5 style={{ letterSpacing: 0 }}>رقم الانتساب </h5>

                                                                        </div>
                                                                        <input style={{ textAlign: 'center' }} className="fname" type="number" name="First" placeholder="رقم الانتساب  "
                                                                            onChange={(e) => { setState({ s_number: e.target.value }) }} />

                                                                        <div className='continerLabelReg'>

                                                                            <h5 style={{ letterSpacing: 0 }}>تاريخ الانتساب</h5>
                                                                        </div>
                                                                        <Flatpickr name='تارخ الانتساب' style={{ textAlign: 'center' }}
                                                                            value={state.join_date}
                                                                            onChange={(date) => { setState({ join_date: date }) }}
                                                                        />
                                                                        <div className='continerLabelReg'>

                                                                            <h5 style={{ letterSpacing: 0 }}>تاريخ النفاذ</h5>
                                                                        </div>
                                                                        <Flatpickr name='تارخ النفاذ' style={{ textAlign: 'center' }}
                                                                            value={state.ex_date}
                                                                            onChange={(date) => { setState({ ex_date: date }) }}
                                                                        />
                                                                    </div>
                                                                </Dialog>
                                                                <Prints onClick={() => { setState({ isShown: true }) }} style={{ color: '#ffc107', cursor: 'pointer' }} />
                                                            </Pane>
                                                        )}
                                                    </Component> :
                                                    <Link to={`/PrintCard/${this.props.match.params.id}`} >
                                                        <Prints style={{ color: '#ffc107', cursor: 'pointer' }} />
                                                    </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id='capture' style={{ width: '100%', direction: 'rtl' }}>

                                    <div id='textDescPageContinerAdmin' >
                                        <Row style={{ padding: 0, margin: 10, marginTop: 20 }}>
                                            <Col sm={12} md={12} lg={6}>

                                                <div id='textDescPageContiner1'>
                                                    <div id='textDescPagelabel' style={{ fontSize: 14 }}>رقم الهويه </div>
                                                    <div id='textDescPagecontent' style={{ fontSize: 14 }}>{this.state.items.s_number}</div>
                                                </div>
                                                <div id='textDescPageContiner1'>
                                                    <div id='textDescPagelabel' style={{ fontSize: 14 }}>الاسم </div>
                                                    <div id='textDescPagecontent' style={{ fontSize: 14 }}>{this.state.items.name}</div>
                                                </div>
                                                <div id='textDescPageContiner1'>
                                                    <div id='textDescPagelabel' style={{ fontSize: 14 }}>name</div>
                                                    <div id='textDescPagecontent' style={{ fontSize: 14 }}>{this.state.items.name_en}</div>
                                                </div>
                                                <div id='textDescPageContiner1'>
                                                    <div id='textDescPagelabel' style={{ fontSize: 14 }}>الجنس </div>
                                                    <div id='textDescPagecontent' style={{ fontSize: 14 }}>{this.state.items.gender === 0 ? 'ذكر' : 'انثى'}</div>
                                                </div>
                                                <div id='textDescPageContiner1'>
                                                    <div id='textDescPagelabel' style={{ fontSize: 14 }}>الهاتف </div>
                                                    <div id='textDescPagecontent' style={{ fontSize: 14 }}>{this.state.items.phone}</div>
                                                </div>
                                                <div id='textDescPageContiner1'>
                                                    <div id='textDescPagelabel' style={{ fontSize: 14 }}>E-mail </div>
                                                    <div id='textDescPagecontent' style={{ fontSize: 14 }}>{this.state.items.email}</div>
                                                </div>
                                                <div id='textDescPageContiner1'>
                                                    <div id='textDescPagelabel' style={{ fontSize: 14 }}>عنوان السكن </div>
                                                    <div id='textDescPagecontent' style={{ fontSize: 14 }}>{this.state.items.governorate + ' / ' + this.state.items.city}</div>
                                                </div>
                                                <div id='textDescPageContiner1'>
                                                    <div id='textDescPagelabel' style={{ fontSize: 14 }}>عضو في الجمعية ؟</div>
                                                    <div id='textDescPagecontent' style={{ fontSize: 14 }}>{this.state.items.Emp===0?"نعم":"كلا"}</div>
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12} lg={6}>
                                                <div id='textDescPageContiner1'>
                                                    <div id='textDescPagelabel' style={{ fontSize: 14 }}>العنوان الوظيفي </div>
                                                    <div id='textDescPagecontent' style={{ fontSize: 14 }}>{this.state.items.certificate}</div>
                                                </div>
                                                <div id='textDescPageContiner1'>
                                                    <div id='textDescPagelabel' style={{ fontSize: 14 }}>مكان العمل </div>
                                                    <div id='textDescPagecontent' style={{ fontSize: 14 }}>{this.state.items.work}</div>
                                                </div>
                                                <div id='textDescPageContiner1'>
                                                    <div id='textDescPagelabel' style={{ fontSize: 14 }}>محل الولاده </div>
                                                    <div id='textDescPagecontent' style={{ fontSize: 14 }}>{this.state.items.pob}</div>
                                                </div>
                                                <div id='textDescPageContiner1'>
                                                    <div id='textDescPagelabel' style={{ fontSize: 14 }}>تاريخ الولاده </div>
                                                    <div id='textDescPagecontent' style={{ fontSize: 14 }}>{moment(this.state.items.dob).format('YYYY/MM/DD')}</div>
                                                </div>



                                                <div id='textDescPageContiner1'>
                                                    <div id='textDescPagelabel' style={{ fontSize: 14 }}>تاريخ الانتساب </div>
                                                    <div id='textDescPagecontent' style={{ fontSize: 14 }}>{moment(this.state.items.join_date).format('YYYY/MM/DD')}</div>
                                                </div>
                                                <div id='textDescPageContiner1'>
                                                    <div id='textDescPagelabel' style={{ fontSize: 14 }}>تاريخ النفاذ </div>
                                                    <div id='textDescPagecontent' style={{ fontSize: 14 }}>{moment(this.state.items.ex_date).format('YYYY/MM/DD')}</div>
                                                </div>
                                                <div id='textDescPageContiner1'>
                                                    <div id='textDescPagelabel' style={{ fontSize: 14 }}>الحاله </div>
                                                    <div id='textDescPagecontent' style={{ fontSize: 14 }}>{this.state.items.status === 0 ? 'قيد الانتظار' : this.state.items.status === 1 ? 'تم الانجاز' : 'تم الالغاء'}</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>


                                    <Row style={{ padding: 0, margin: 10, marginTop: 20 }}>
                                        <Col sm={12} >
                                            <div id='textDescPageContinerImgAdmin'>
                                                <img src={host + 'item/files/' + this.state.items.photo} alt='Identity' style={{ width: 'auto', height: 280 }} />
                                            </div>
                                        </Col>

                                    </Row>
                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                            :
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
                                <Spinner />
                            </div>
                    );

                }
                }
            </Context.Consumer>
        )
    }

}
export default Office
