import React from 'react'
import Info from '@material-ui/icons/Info';
import Cookies from "universal-cookie";
import { Tabs, Tab } from 'react-bootstrap';
import "flatpickr/dist/themes/dark.css";
import Paper from '@material-ui/core/Paper';
import host from '../../assets/js/Host';
import axios from 'axios';
import * as moment from "moment";
import MaterialDatatable from "material-datatable";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';

const columns = [
    { field: "info", name: "تفاصيل ", options: { width: 100, filter: true, sort: true, } },
    { field: "date", name: "تاريخ ", options: { width: 200, filter: true, sort: true, } },
    { field: "address", name: "العنوان", options: { filter: true, sort: true, } },
    { field: "phone", name: "رقم الهاتف", options: { filter: true, sort: true, } },
    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
];

const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Watting: [],
            Done: [],
            Cancel: [],
            spin: false,
            Dob: '',
            NumberIn: '',

        }
    }
    getMuiTheme = () => createTheme({
        overrides: {
            MaterialDatatableBodyCell: { root: { textAlign: 'right', } },
            MuiTableCell: { root: { textAlign: 'right' } },
        }
    })

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenAdmin") };
        axios.get(host + `item/items/all?status=0`, { headers: header })
            .then(res => {
                console.log(res.data.items);
                let arr = [];
                for (let i = 0; i < res.data.items.length; i++) {
                    // console.log(res.data.data.users);
                    let obj = {
                        name: res.data.items[i].name,
                        phone: res.data.items[i].phone,
                        address: res.data.items[i].governorate + ' / ' + res.data.items[i].city,
                        date: moment(res.data.items[i].createdAt).format('DD/MM/YYYY'),
                        info: <Link to={`/InfoDash/${res.data.items[i].id}`} >
                            <Info style={{ color: '#ffc107', cursor: 'pointer' }} />
                        </Link>


                    };
                    arr.push(obj);
                }
                this.setState({
                    Watting: arr, spin: true
                });
            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })
        ////////////////////////// done////////////////////////////

        axios.get(host + `item/items/all?status=1`, { headers: header })
            .then(res => {
                // console.log(res.data.data.orders);
                let arr = [];
                for (let i = 0; i < res.data.items.length; i++) {
                    // console.log(res.data.data.users);
                    let obj = {
                        name: res.data.items[i].name,
                        phone: res.data.items[i].phone,
                        address: res.data.items[i].governorate + ' / ' + res.data.items[i].city,
                        date: moment(res.data.items[i].createdAt).format('DD/MM/YYYY'),

                        info: <Link to={`/InfoDash/${res.data.items[i].id}`} >
                            <Info style={{ color: '#ffc107', cursor: 'pointer' }} />
                        </Link>
                    };
                    arr.push(obj);
                }
                this.setState({
                    Done: arr, spin: true
                });
            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })
        // ///////////////////////////////// cancel /////////////////////////////
        axios.get(host + `item/items/all?status=2`, { headers: header })
            .then(res => {
                // console.log(res.data.items);
                let arr = [];
                for (let i = 0; i < res.data.items.length; i++) {
                    // console.log(res.data.data.users);
                    let obj = {
                        name: res.data.items[i].name,
                        phone: res.data.items[i].phone,
                        address: res.data.items[i].governorate + ' / ' + res.data.items[i].city,
                        date: moment(res.data.items[i].createdAt).format('DD/MM/YYYY LT'),
                        info: <Link to={`/InfoDash/${res.data.items[i].id}`} >
                            <Info style={{ color: '#ffc107', cursor: 'pointer' }} />
                        </Link>

                    };
                    arr.push(obj);
                }
                this.setState({
                    Cancel: arr, spin: true
                });
            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })
    }

    render() {
        // if (this.state.spin) {
        return (
            <div>
                <Paper className={'classesroot'} >
                    <Tabs defaultActiveKey={this.state.activetap} id="uncontrolled-tab-example" style={{ backgroundColor: '#fff', }}>
                        <Tab eventKey='wating' title="طلبات قيد الانتظار" tabClassName='wating' onClick={() => this.setState({ activetap: 'wating' })}>
                            <div className='DataTableContiner'>
                                <MuiThemeProvider
                                    theme={this.getMuiTheme()}>
                                    <MaterialDatatable data={this.state.Watting} columns={columns} options={options} />
                                </MuiThemeProvider>
                            </div>
                        </Tab>
                        <Tab eventKey="done" title="طلبات تم انجازها " tabClassName='done' onClick={() => this.setState({ activetap: 'done' })}>
                            <div className='DataTableContiner'>
                                <MuiThemeProvider
                                    theme={this.getMuiTheme()}>
                                    <MaterialDatatable data={this.state.Done} columns={columns} options={options} />
                                </MuiThemeProvider>
                            </div>
                        </Tab>
                        <Tab eventKey="cancel" title="طلبات تم الغاؤها " tabClassName='cancel' onClick={() => this.setState({ activetap: 'cancel' })}>
                            <div className='DataTableContiner'>
                                <MuiThemeProvider
                                    theme={this.getMuiTheme()}>
                                    <MaterialDatatable data={this.state.Cancel} columns={columns} options={options} />
                                </MuiThemeProvider>
                            </div>
                        </Tab>
                    </Tabs>
                </Paper>
        
            </div>
        )
        // }
        // else {
        //     return (
        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        //             <Lottie style={{ width: 300 }}
        //                 options={{
        //                     animationData: animation
        //                 }}
        //             />
        //         </div>
        //     )
        // }
    }
}
export default Category